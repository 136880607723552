<template>
  <v-main>
    <v-tabs
      v-model="tab"
      class="d-flex justify-center"
    >
      <v-tab class="tab-login">
        Restaurar Contraseña
      </v-tab>
    </v-tabs>
    <v-tabs-items v-model="tab">
      <v-tab-item>
        <restore-password />
      </v-tab-item>
    </v-tabs-items>
  </v-main>
</template>

<script>
  import RestorePassword from '@/components/auth/RestorePassword.vue'

  export default {
    name: 'RestorePasswordView',
    components: {
      RestorePassword,
    },
    data () {
      return {
        tab: null,
      }
    },


  }
</script>
<style lang="sass" scoped>
.bg-login
  background-image: url('../../assets/dev/fondo-bg.jpg')
  background-size: cover
  background-position: center
.bg-login-card
  background-image: url('../../assets/dev/fondo-login-card.jpg')
  background-size: cover
  background-position: center
.tab-login
  color: #cacaca !important
  background-color: lightgoldenrodyellow
.tab-login.v-tab--active
  color: #2970e2 !important
  background-color: gainsboro
</style>
