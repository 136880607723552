<template>
  <v-container class="mt-5 pa-6 pa-lg-4 pr-lg-6">
  <v-container class="mt-5 pa-6 pa-lg-4 pr-lg-6" v-if="!success">
    <v-btn 
        color="primary"
        class="mb-4"
        block
        @click.prevent="restore">
      Restaurar
    </v-btn>
  </v-container>
  <v-container class="container mt-5 pa-6 pa-lg-4 pr-lg-6" v-else-if="error">
    <div class="card p-5 p-md-3 p-xl-5 profile"
         style="pointer-events:none">
      <h4 class="text-center pt-4 pb-2">
          Ha ocurrido un error
      </h4>
      <p class="text-center">
        No se ha encontrado el usuario 
      </p>
    </div>
  </v-container>
  <v-container class="container mt-5 pa-6 pa-lg-4 pr-lg-6" v-else>
    <div class="card p-5 p-md-3 p-xl-5 profile"
         style="pointer-events:none">
      <h4 class="text-center pt-4 pb-2">
          Correo enviado
      </h4>
      <p class="text-center">
        Hemos enviado un link de restauración 
      </p>
    </div>
  </v-container>
  <router-link class="px-4 pb-8 restore-login" to="/login">
    Iniciar sesión
  </router-link>
  <router-link class="px-4 pb-8 restore-login" to="/">
    Home
  </router-link>
  </v-container>
</template>

<script>
import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import VueAxios from 'vue-axios'

Vue.use(Vuex)
Vue.use(VueAxios, axios)

Vue.axios.defaults.baseURL = process.env.VUE_APP_HOST
Vue.axios.defaults.headers.post['Content-Type'] = 'application/json'
Vue.axios.defaults.xsrfHeaderName = 'X-CSRFToken'
Vue.axios.defaults.xsrfCookieName = 'csrftoken'


export default {
  data() {
    return {
      error: false,
      success: false,
      email :'',
      rules: {
        required: (value) => !!value || "Required",
        min: (v) => v.length >= 8 || "Min 8 characters",
        email: v => !v ||/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(v) || 'Please enter a valid email',
      },
    };
  },
  methods: {
      restore () {
        console.log('CLICK RESTAURER')

        this.error = false
        const payload = {
          email: this.email
        }

        window.location.href = process.env.VUE_APP_HOST+'auth/password_reset/'
        //this.$router.push(process.env.VUE_APP_HOST+'auth/password_change/')

        // NOT WORKING
        //Vue.axios.post('auth/reset_password/', payload)
        //  .then((response) => {
        //    console.log('REQUEST with payload ', payload)
        //    if (response.status === 200) {
        //      console.log( 'FIRST RESPONSE SUCCESS, WILL VERIFY ')
        //      console.log( response )
        //      this.success = true    
        //    } else {
        //      console.log( 'ERROR ' )
        //      this.error = true
        //    }
        //  })
        //  .catch((error) => {
        //    this.success = true
        //    this.error = true
        //    throw new Error(`API ${error}`)
        //  })
        //Vue.axios.post('auth/reset_password/', payload)
        //  .then((response) => {
        //    console.log('REQUEST with payload ', payload)
        //    if (response.status === 200) {
        //      console.log( 'FIRST RESPONSE SUCCESS, WILL VERIFY ')
        //      console.log( response )
        //      this.success = true    
        //    } else {
        //      console.log( 'ERROR ' )
        //      this.error = true
        //    }
        //  })
        //  .catch((error) => {
        //    this.success = true
        //    this.error = true
        //    throw new Error(`API ${error}`)
        //  })
      },
  }
};
</script>
<style lang="sass" scoped>

</style>
